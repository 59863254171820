import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Mycontext } from '../../contexts/Context_1';
import { PopUp } from '../../contexts/PopContext';
import axios from 'axios';
import './form.css'
import { LiaTimesSolid } from "react-icons/lia";



function ContactForm({className}) {
  const location = useLocation();
  const currentPath = location.pathname;

  const { t } = useTranslation("translate");
  const { handleContactStat } = useContext(Mycontext);
  const { handlepopUp, setIsDekont } = useContext(PopUp);
  const [file, setFile] = useState(null)
  const [fileDataURL, setFileDataURL] = useState(null);
  const [isNull, setIsNull] = useState(false);
  const PORT = process.env.REACT_APP_PORT;
  const PORT_2 = process.env.REACT_APP_PORT_2;

  const [phone, setPhone] = useState("90");
  const [subject, setSubject] = useState("Yeni satın alma");


  const handleSendEmail = async (e) => {
    const fullName = e.target[0].value
    const email = e.target[1].value
    //const phone = e.target[2].value
    //const subject = e.target[3].value
    const message = e.target[4].value
    try {
      const fileBase64 = file ? await convertFileToBase64(file) : null;

      const response = await axios.post(`${PORT_2}`, { 
        fullName,
        email,
        phone,
        subject,
        message,
        fileBase64,
      }, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': PORT,
          },
        })
      if (response.status === 200) {
        //console.log("response", response);
        handleContactStat();
        setPhone("");
        setSubject("");
        setFileDataURL(null);
        if(currentPath === "/cart" && file) {
          setIsDekont(true);
          /* setTimeout(() => {
            setIsDekont(false);
          }, 5000); */
        } else {
          handlepopUp("green");
        }
         e.target[0].value = "";
         e.target[1].value = "";
         e.target[4].value = "";
      }
    } catch (error) {
      console.log('Error sending emailll:', error, 'the port is',PORT);
      handlepopUp("red")
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  }


  const handleBothActions = (e) => {
    e.preventDefault();
    if(currentPath === "/cart" && !file) {
      setIsNull(true);
      return;
    }
    setIsNull(false);
    handleSendEmail(e);
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => setFileDataURL(reader.result);
      reader.readAsDataURL(selectedFile);

      setFile(selectedFile);
    }
  }

  //const i1 = {'--i': 1};
  const i2 = {'--i': 2};
  const i3 = {'--i': 3};
  const i4 = {'--i': 4};
  //const i5 = {'--i': 5};
  const i6 = {'--i': 6};

  return (
   <>
    <form onSubmit={e => {handleBothActions(e)}} className={className}>
      <div className="icon-cont" onClick={handleContactStat}><LiaTimesSolid /></div>
      <div className="input-1">
        <input type="text" required placeholder={t("contact.name")} />
        <input type="email" required placeholder={t("contact.email")} />
        <span className="animate" style={i2}></span>
      </div>
      <div className="input-2">
        <input 
          type="number" 
          value={phone}  
          required 
          placeholder={t("contact.tele")} 
          onChange={(e)=> {setPhone(e.target.value)}} 
          maxlength="12"
        />
        <input 
          value={subject} 
          type="text" 
          required 
          placeholder={t("contact.subj")} 
          onChange={(e)=> {setSubject(e.target.value)}}
        />
        <span className="animate" style={i3}></span>
      </div>
      <div className='txtarea_cont'>
        <textarea cols="30" rows="10" required placeholder={t("contact.message")}>
      </textarea>
      <span className="animate" style={i4}></span>
      </div>
       <div className="btn-cont">
         <input type="file" accept="image/*" style={{display: "none"}} id='pdf' onChange={handleFileChange}/>
          {fileDataURL && (
            <div id='choosendiv'>
              <img src={fileDataURL} alt="file" />
              <span></span>
            </div>
          )}
          <label htmlFor="pdf" className={`btn nohover ${isNull && "null"}`}>{t("contact.choose")}</label>
          <button className="btn" type='submit'>{t("contact.a")}
          </button>
          <span className="animate" style={i6}></span>
       </div>
     </form>
    </>
  )
}

export default ContactForm;
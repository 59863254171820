// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import global_en from "./locales/en/translate.json";
import global_tr from "./locales/tr/translate.json";
import getUsersLocation from "./getUsersLocation";

const lang = (await getUsersLocation()) || "tr";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: lang,
    resources: {
      en: { translate: global_en },
      tr: { translate: global_tr },
    },
  });

export default i18n;

import React, { createContext, useContext, useState } from 'react';
import prod_1 from "../assets/prods/liwasoft.png" //efatura
import prod_2 from "../assets/prods/ekterminal.png" //primepatron
import prod_3 from "../assets/prods/patron.png" //primepatron
import prod_4 from "../assets/prods/SMS.png" //SMS
import prod_5 from "../assets/prods/efatura.png" //efatura
import axios from 'axios';
import { PopUp } from './PopContext';




const currency = process.env.REACT_APP_CURRENCY_API;
const Mycontext = createContext();

const MyProvider = ({ children }) => {
  const [promoStat, setPromoStat] = useState(false);
  const [contactStat, setContactStat] = useState(false);
  const [prices, setPrices] = useState([]);
  const [TRY, setTRY] = useState()
  const [ytLink, setYtLink] = useState(null);
  const { handlepopUp } = useContext(PopUp);
  
  let tempPrices = [
    {
      title: "prod-1",
      src: prod_1,
      price: 1000, // Updated price
      kdv: 200, // Updated KDV (20 percent of the updated price)
      total: 1000 + 200, // Updated total
      amount: 0
    },

    {
      title: "prod-2",
      src: prod_2,
      price: 100, // Updated price
      kdv: 20, // Updated KDV (20 percent of the updated price)
      total: 100 + 20, // Updated total
      amount: 0
    },

    {
      title: "prod-3",
      src: prod_3,
      price: 110, // Updated price
      kdv: 21, // Updated KDV (20 percent of the updated price)
      total: 109 + 21, // Updated total
      amount: 0
    },
  
    {
      title: "prod-4",
      src: prod_4,
      price: 100,
      kdv: 20,
      total: 100 + 20,
      amount: 0
    },
  
    {
      title: "prod-5",
      src: prod_5,
      price: 109,
      kdv: 21.8,
      total: 109 + 21.8,
      amount: 0
    },
  ];
  

  const handlePrices = (title, sign) => {
    //console.log(title, sign)
    let localPrice = JSON.parse(localStorage.getItem("prices"));
    if (localPrice !== null) {
      setPrices(localPrice);
      tempPrices = localPrice;
    } else {
      localStorage.setItem("prices", JSON.stringify(tempPrices));
      setPrices(JSON.parse(localStorage.getItem("prices")));
    }
    tempPrices.forEach((item)=> {
      if(item.title === title) {
        item.amount += sign;
        localStorage.setItem("prices", JSON.stringify(tempPrices));
        setPrices(JSON.parse(localStorage.getItem("prices")));
      }
    })
    if (sign !== -1) {
      handlepopUp("added");
    } else if (sign === -1) {
      handlepopUp("removed");
    }
    //console.log("metual");
    //localStorage.removeItem("prices")
    //console.log(localStorage.getItem(prices))
  }
  const handlePromo = (src) => {
    setPromoStat(!promoStat)
    setYtLink(src);
  }
  const handleContactStat = () => {
    setContactStat(!contactStat);
    //console.log("Active");
  }

  /* Currency */
  const getExchangeRate = async () => {
    try {
      const response = await axios.get(currency);
      //console.log("response",response);
      const exchangeRate = response.data.rates.TRY;
      return exchangeRate;
    } catch (error) {
      //console.error('Error fetching exchange rate:', error);
    }
  };
  getExchangeRate().then(rate => {
    console.log('USD to TRY Rate:', rate);
    setTRY(rate);
  })


  return (
    <Mycontext.Provider value={{ 
      promoStat, handlePromo, 
      contactStat, handleContactStat, 
      prices, handlePrices, setPrices, 
      TRY,
      ytLink,
      tempPrices,
      }}>
      {children}
    </Mycontext.Provider>
  );
};
export {MyProvider, Mycontext};
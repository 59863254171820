import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import Promo from './promo/Promo';
import About from './about/About';
import Landing from './landing/Landing';

function Home() {
  localStorage.removeItem("prices")
  //console.log('removed');
  return (
    <>
      <Header/>
      <Promo/>
      <Landing/>
      <About/>
      <Footer/>
    </>
  )
}

export default Home;
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Mycontext } from '../../contexts/Context_1';
import LanguageSwitcher from '../lang/LangSwitcher';
import logo from "../../assets/logo/logo.png"
import './header.css'

//Icons 
import { RxHamburgerMenu } from "react-icons/rx";
import { LiaTimesSolid } from "react-icons/lia";




function Header() {
  const { t } = useTranslation("translate");
  const [isactive, setIsactive] = useState(false);
  const { prices } = useContext(Mycontext);
  const [Cart, setCart] = useState(0);


  useEffect(()=> {
    const localPrice = JSON.parse(localStorage.getItem("prices"));

    if (localPrice != null) {
    let cart = 0;
    localPrice.map((item) => {
      cart += item.amount;
      //console.log(item.amount)
      setCart(cart);
      return cart;
    })
    }
  }, [prices])


  const handleActive = () => {
    setIsactive(!isactive);
  }

  
  return (
    <div>
      <header>
          <a href="/">
          <div className="logo">
              <img src={logo} alt="Logo"/>
              <p>Liwa Prime</p>
          </div>
          </a>

          <nav className={`${isactive ? "active" : ""}`}>
            <ul className="nav-links">
              <a href="/" onClick={handleActive}>{t('header.home')}</a>
              <a href="#about" onClick={handleActive}>{t('header.aboutUs')}</a>
              <a href="products" onClick={handleActive}>{t('header.products')}</a>
              <a href='https://liwapos.com:4691' onClick={handleActive}>Müşteri Paneli</a>
              <a className='acart' href='cart' onClick={handleActive}>{t('header.cart')}<span>{Cart}</span></a>
            </ul>

            {/* <div className='language'>
              <LanguageSwitcher/>
            </div> */}

            <span className="active-nav"></span>
        </nav>
        <div id='menu-icon' onClick={handleActive} style={{fontSize: "1.4rem"}}>
          {isactive ?  (<LiaTimesSolid/>) : (<RxHamburgerMenu/>)}
         {/*  <i className={`bx ${isactive ? "bx-x" : "bx-menu"}`} ></i> */}
        </div>
      </header>
    </div>
  )
}

export default Header;

import { useContext } from 'react';
import { Mycontext } from '../../contexts/Context_1';
import './promo.css'

import { LiaTimesSolid } from "react-icons/lia";



function Promo({src}) {
  const { promoStat, handlePromo, ytLink} = useContext(Mycontext);
  return (
    <div id='Promo_cont' className={promoStat? "active": ""}>
        <div className="container">
          <div className="closeyt" onClick={handlePromo}><LiaTimesSolid /></div>
          <iframe src={ytLink + "&autoplay=1"} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen ></iframe>
        </div>
    </div>
  )
}

export default Promo;
import React, { useContext } from 'react';
import './nedir.css'; // Import your CSS file
import { Link } from 'react-router-dom';
import { PopUp } from '../../contexts/PopContext';

function Nedir() {
  const { nedir, setNedir } = useContext(PopUp);
  return (
    <div className={`nedir_cont ${nedir ? "open" : ""}`}>
      <div className="nedir">
        <h1>Liwa Prime Araç Servis Takip Yazılımı</h1>
        <p>
        Liwa Prime Araç Servis Takip Yazılımı Tamirhane-Atölye içerisindeki tüm durumları takip etmek ve kontrol altında tutmak için yaptığımız Kullanımı Pratik ve herkesin birkaç dakika içinde kolayca öğrenebileceği %100 Türkçe bir yazılımdır.
        <br/>
        Otomobil, Motosiklet, Kamyon, Tır gibi Tüm Tekerle Yürüyen Araç Servisleri Kullanabilir.Birden fazla bilgisayarda kullanılabilir.
        <br/>
        Servis Mekanik veya Kaporta olarak 2 departmanda çalışıyor olabilir, her iki departman içinde takip sağlanabilir. Mekanik ve Kaporta departmanları için Ayrı kullanıcılar oluşturulabilir. 
        <br/>
        Servise Mekanik Bölümünden giriş yapan aracın sonrasında Kaporta Departmanına aktarımını tek tuş ile yapabilirsiniz. Aynı zamanda servise ilk giriş Kaporta bölümünden ise ve kaporta da işi bitmişse Mekanik bölümüne aktarımını tek tuş ile kolayca yapabilirsiniz.
        <br/>
        Mekanik Teknisyeni Kaporta bölümünün aracına yetki verilmediği sürece müdahale edemez, Kaporta Teknisyeni de Mekanik bölümünün aracına yetki  verilmediği sürece müdahale edemez.
        <br/>
        Mekanik ve Kaporta haricinde Elektrik-Yedek Parça gibi departmanlarınız var ise kolayca eklenebilir.
        <br/>
        Servis dışında yaptırılan işlemler var ise kolayca belge içerisinde manuel parça veya işçilik eklenebilir. Örnek vereyim Radyatör delinmesinden oluşan Radyatör Kaynak ve Tamir işini servis dışında yaptırdınız diyelim, belge içinde tek tuşla yapılan işlemi ve fiyatını ekleyerek manuel işlem veya parçayı belge içine kolayca ekleyebilirsiniz.
        <br/>
        Belge içinde yapılan tüm işlemler kayıt altındadır. Hangi teknisyen belge içinde ne işlem yaptıysa saat tarih olarak kayıt altındadır.
        <br/>
        Aracın Servis girişi kilometresi, yakıt seviyesi gibi durumları takip edebilirsiniz. 
        <br/>
        Test sürüşüne çıkılacağı zaman Test Sürüşü Öncesi aracın kilometresi ve test sürüşünden döndükten sonra aracın kilometresi gibi takip edilmesi gereken durumları takip edebilirsiniz.
        <br/>
        Aracın servis çıkışı öncesi yıkanması gerekiyor ise belge içinde "Servis Çıkışı Araç Yıkansın" seçeneği ile araç yıkama ücreti otomatik olarak eklenecektir.
        <br/>
        Aracın onarım işlemlerinde takip etmek amaçlı "Sorumlu Teknisyen" atayabilirsiniz.
        <br/>
        Araç periyodik bakım için gelmiş ise tek tuş ile periyodik bakım işlemlerini araç belgesine ekleyebilirsiniz.Fiyat hesaplaması otomatik olarak çalışacaktır.
        <br/>
        Aracın servise girişinden çıkışına kadar yapılan tüm işlemleri Patronun cep telefonuna bildirim olarak gönderilebilir.(Patron Modülü)
        <br/>
        Aracın servisteki aşamalarını ve istenilen durumları Müşterinin cep telefonuna SMS ile gönderilebilir.(SMS Modülü)
        <br/>
        Araç servise daha sonra geldiğinde daha önce ne işlemler yapılmış, hangi tarihte yapılmış, hangi teknisyen işlemleri yapmış detaylı ve kolay şekilde aracın geçmiş bilgilerine ulaşabilirsiniz.
        <br/>
        Personel maaş, haftalık, yevmiye takibi yapabilirsiniz.
        <br/>
        Tedarikçi yani mal veya hizmet aldığınız kişi ve kurumlara borç-alacak takibini yapabilirsiniz.
        <br/>
        Müşterilerinizin borç-alacak durumlarını takip edebilirsiniz.
        <br/>
        Banka Hesaplarınızın gelir-gider durumlarını takip edebilirsiniz.
        <br/>
        Stok takibi yapabilirsiniz, günlük harcamalarınızı takip edebilirsiniz.
        <br/>
        Günlük, haftalık, aylık yapılan tüm işlemlerin raporlarını görebilirsiniz.
        <br/>
        Kar-Zarar-Maliyet hesabını otomatik olarak yapacaktır, detaylı şekilde görebilirsiniz.
        <br/>
        Yazılımı satın aldığınızda tek seferlik 1000$ ödeme yaparsınız ve ömür boyu kullanabilirsiniz.
        <br/>
        Ek Terminal lisansı birden fazla bilgisayarda kullanım imkanı sunuyor, sınır yoktur, satın aldığınız ek terminal lisansı sayısı kadar bilgisayarda yazılımı kullanabilirsiniz. Ek terminal lisansı 100$ dır.
        <br/>
        Sms Modülü yani SMS ile Müşteri Bilgilendirme yıllık 100$ dır.
        <br/>
        Yazılımı Patron Modülü(Uzaktan Kontrol Modülü) ile Satın Aldığınızda Anlık Kasa Durumu, Fiyat Değişikliği ve Tüm İşlem Raporlarını IOS ve Android tüm cep telefonlarından görebilir ve işlem yapabilirsiniz. Patron modülümüz yıllık 109$ dır.
        <br/>
        E-Fatura E-Arşiv modülü ile onarımı yapılan aracın resmi olarak faturalandırma işlemlerini otomatik olarak yapabilirsiniz.İsterseniz Manuel olarakta fatura kesimi yapabilirsiniz. E-fatura modülü yıllık 109$ dır.Ayrıca işletmenizin araç girdi-çıktı yoğunluğuna göre kontör harcaması tespit edilerek E-Fatura kontörü satın almanız gerekmektedir.Kontör ücreti E-Fatura modülünden hariçtir.
        <br/>
        Yazılım içeriği istenilen şekilde değiştirilebilir, istenilen özellikler eklenebilir, çıkartılabilir kısacası esnek bir yapıya sahiptir.
      </p>
      </div>

      <div className="btn-cont">
        <Link className='btn' onClick={()=> setNedir(!nedir)}>Kapat</Link>
      </div>
    </div>
  );
}

export default Nedir;

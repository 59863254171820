import React, { useContext } from 'react'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Promo from '../promo/Promo';
import './products.css'

import prod_1 from "../../assets/prods/liwasoft.png" //liwasoft
import prod_2 from "../../assets/prods/ekterminal.png" //primepatron
import prod_3 from "../../assets/prods/patron.png" //primepatron
import prod_4 from "../../assets/prods/SMS.png" //SMS
import prod_5 from "../../assets/prods/efatura.png" //efatura

import { useTranslation } from 'react-i18next';
import { Mycontext } from '../../contexts/Context_1';
import { Link } from 'react-router-dom';

<iframe width="560" height="315" src="https://www.youtube.com/embed/oOUYjVROp3c?si=9YjJnKirozRq1Yb_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

function Products() {
  const { t } = useTranslation("translate");
  const { handlePrices } = useContext(Mycontext);
  const { tempPrices } = useContext(Mycontext);
  const prods = [ 
    {prod: prod_1, price: tempPrices[0].price},
    {prod: prod_2, price: tempPrices[1].price}, 
    {prod: prod_3, price: tempPrices[2].price}, 
    {prod: prod_4, price: tempPrices[3].price}, 
    {prod: prod_5, price: tempPrices[4].price},
  ];
  

  return (
    <div>
      <Header/>
        <section id='Products'>
          <Promo/>
          <div className="Products-container">
              <div className="title"><h1>{t("header.products")} <span></span></h1></div>
              {prods && prods.map((prod, i) => (
               <div className={`Prod_cart Prod_${i+1}`} key={`prod_${i}`}>
                 <div className='img-bg'>
                 <div className="img-cont">
                    <img src={prod.prod} alt={`Prod_${i+1}`} />
                    {/* <div className="yt_cont"> 
                      {prod.src ? (<img src={YT} alt="YT" onClick={()=> handlePromo(prod.src)}/>): null}
                    </div> */}
                 </div>
                 </div>

                 <div className="subtexts">
                     <h2>{t(`Product.prod-${i+1}.title`)}<br/>
                       <span className='subcolor'>{prod.price} {t(`Product.prod-${i+1}.span`)}</span>
                     </h2>
                     <p>{t(`Product.prod-${i+1}.txt`)}</p>
                   <div className="btn-cont">
                     <Link onClick={() => handlePrices(`prod-${i+1}`, 1)} className="btn">{t("Product.prod-1.a-1")}</Link>
                   </div>
                 </div>
               </div>
              ))}
          </div>
        </section>
      <Footer/>
    </div>
  )
}

export default Products;
import React, { useEffect, useRef, useState } from 'react'
import logo from "../../assets/logo/logo.png"
import { useTranslation } from 'react-i18next';
import './footer.css'

// Icons
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { IoLogoYoutube } from 'react-icons/io5';
import { SiWhatsapp } from 'react-icons/si';
import { MdOutlineAddIcCall } from "react-icons/md";



function Footer() {
  const { t } = useTranslation("translate");
  const footerRef = useRef(null);
  const [cName, setcName] = useState(null);

  
  useEffect(() => {
    const calcOff = () => {
      const top = window.scrollY + 1000;
      const offset = footerRef.current.offsetTop;
      const height = footerRef.current.offsetHeight;
      if (top >= offset && top < (offset+height)) {
        setcName("start_animate")
      } else {
        //setcName(null);
      }
    };
    window.addEventListener('scroll', calcOff);
    calcOff();
    return () => {
      window.removeEventListener('scroll', calcOff);
    };
  }, [footerRef]);

  const i1 = {'--i': 0};
  const i2 = {'--i': 1};
  const i3 = {'--i': 2};
  const i4 = {'--i': 2};
  const i5 = {'--i': 3};
  const i6 = {'--i': 3};
 
  return (
    <>
     {/* ------ Footer section ------- */}
      <section id='footer' ref={footerRef} className={cName && `${cName}`}>
        <div className="footer-cont">
          <div className="footer-links">

            <div className="footer-1 link-cont">
              <div className="logo">
                  <img src={logo} alt="Logo"/>
                  <p>Liwa Prime</p>
                  <span className="animate" style={i1}></span>
              </div>
              <p>
                {t("footer.p-1")}
                <br />
                <br />
                <em> <MdOutlineAddIcCall /> 0850 840 78 07</em>
                <br />
                <em> <MdOutlineAddIcCall /> 0539 294 35 99</em>
                <span className="animate" style={i2}></span>
              </p>
            </div>

            <div className="footer-2 link-cont">
              <p>{t("footer.p-2")}</p>
              <a href="/">{t("header.home")}</a>
              <a href="#about">{t("header.aboutUs")}</a>
              <a href="#product">{t("header.products")}</a>
              <a href="https://liwapos.com:4691">Müşteri Paneli</a>
              <a href="cart">{t("header.cart")}</a>
              <span className="animate" style={i3}></span>
            </div>

            <div className="footer-3 link-cont">
              <p>{t("header.products")}</p>
              <a href="products">{t("Product.prod-1.title")}</a>
              <a href="products">{t("Product.prod-2.title")}</a>
              <a href="products">{t("Product.prod-3.title")}</a>
              <div className="social">
                <a href='https://www.facebook.com/liwasoft/' className="fb"><FaFacebookF /></a>
                <a href='https://www.instagram.com/liwasoft/' className="ins"><FaInstagram/></a>
                <a href='https://www.youtube.com/channel/UCk9flKQF502tzxmDo_4VPbw' className="yt"><IoLogoYoutube/></a>
                <a href='https://web.whatsapp.com/send?phone=908508407807&text=Merhaba' className="wa"><SiWhatsapp/></a>
              </div>

              <span className="animate" style={i4}></span>
            </div>

            <div className="footer-4 link-cont">
              <p></p>
              {/* <a href="products">{t("footer.a-7")}</a>
              <a href="products">{t("footer.a-8")}</a>
              <a href="products">{t("footer.a-9")}</a>
              <a href="products">{t("footer.a-10")}</a>
              <a href="products">{t("footer.a-11")}</a>
              <a href="products">{t("footer.a-12")}</a> */}
              
              <span className="animate" style={i5}></span>
            </div>

          </div>

          <div className="footer-copy">
            <p>{t("footer.copy")}</p>
            <a href='https://www.ibrahimali.net'>Ibrahim Ali</a>
            <span className="animate" style={i6}></span>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer;
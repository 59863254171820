import './popup.css'
import check_icon from '../../assets/popup/check-2.jpg'
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PopUp } from '../../contexts/PopContext';




const Popup = () => {
  const { isDekont } = useContext(PopUp);
  const { t } = useTranslation("translate");
  const { setIsDekont } = useContext(PopUp);

  const handleClick = () => {
    setIsDekont(false);
  }

  return (
    <div className={`thank-container ${!isDekont ? 'hide_cont' : ''}`}>
      <div className="thank-div">
        <div className="thank-box">
          <span>
            <img src={check_icon} alt="check_icon" />
          </span>
          <h1 className="tr-pa-1">{t("popup.green")}</h1>
          <p className="tr-pa-2">{t("popup.file")}</p>
          <button onClick={handleClick} className="tr-pa-3">{t("popup.ok")}</button>
        </div>
      </div>
    </div>   
  )
}
 
export default Popup;

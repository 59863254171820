import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LazyLoad from 'react-lazyload';


import img_1 from "../../assets/gallery/1.jpg"
import img_2 from "../../assets/gallery/2.jpg"
import img_3 from "../../assets/gallery/3.jpg"
import img_4 from "../../assets/gallery/4.jpg"
import img_5 from "../../assets/gallery/5.jpg"
import img_6 from "../../assets/gallery/6.jpg"
import img_7 from "../../assets/gallery/7.jpg"
import img_8 from "../../assets/gallery/8.jpg"
import img_9 from "../../assets/gallery/9.jpg"
import img_10 from "../../assets/gallery/10.jpg"
import img_11 from "../../assets/gallery/11.jpg"
import img_12 from "../../assets/gallery/12.jpg"
import img_13 from "../../assets/gallery/13.jpg"
import img_14 from "../../assets/gallery/14.jpg"
import img_15 from "../../assets/gallery/15.jpg"
import "./Carousel.css"
//import { useTranslation } from 'react-i18next';


const Carousel = () => {

  //const { t } = useTranslation("translate");
  const images = [img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_12, img_13, img_14, img_15]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:2000, 
    arrows: false,
  };

  return (
    <div id='Carousel'>
      <Slider {...settings}>
        {images.map((img, i) => (
        <div className='img_cont'>
          <LazyLoad>
            <img src={img} alt={`Slide${i}`} loading='lazy' key={i}/>
          </LazyLoad>
        </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;

import axios from "axios";

const lociationApi = process.env.REACT_APP_LANGUAGE_API;

const getUsersLocation = async () => {
  const handleUsersLocation = async () => {
    try {
      const response = await axios.get(lociationApi);
      const country = response.data.countryCode;
      switch (country) {
        case "US":
        case "CA":
          return "en";
        case "TR":
          return "tr";
        case "SA":
          return "arab";
        case "DE":
          return "de";
        case "FR":
          return "fr";
        default:
          return "tr";
      }
    } catch (error) {
      console.log(error);
      return "tr";
    }
  };

  let lang = "";

  if (!localStorage.getItem("Local_Language")) {
    const locational_Lang = await handleUsersLocation();

    localStorage.setItem("Local_Language", locational_Lang);
    lang = localStorage.getItem("Local_Language");
    return lang;
  } else {
    lang = localStorage.getItem("Local_Language");
    return lang;
  }
};

export default getUsersLocation;
